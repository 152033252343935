<template>
  <div>
    <el-card>
      <el-form :model="userInfo" ref="userInfo">
        <el-form-item :label="$t('order.account')" label-width="120px" prop="account">
          <el-input v-model="userInfo.account" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('login.password')" label-width="120px" prop="password">
          <el-input v-model="userInfo.password" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('role.phone')" label-width="120px" prop="mobile">
          <el-input v-model="userInfo.mobile" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('center.balance')" label-width="120px" prop="balance">
          <el-input v-model="userInfo.balance" readonly style="width: 200px"></el-input>
          <el-button style="margin-left: 20px" type="primary" @click="handlePay(1)">{{
              $t('amount.recharge')
            }}
          </el-button>
        </el-form-item>
        <el-form-item :label="$t('center.expire')" label-width="120px" prop="system_valid">
          <el-input v-model="userInfo.system_valid" readonly style="width: 200px"></el-input>
          <el-button style="margin-left: 20px" type="primary" @click="handlePay(2)">{{
              $t('center.renewal')
            }}
          </el-button>
        </el-form-item>
        <el-form-item :label="$t('center.sms')" label-width="120px" prop="sms_status">
          <el-radio :label="1" v-model="userInfo.sms_status">{{ $t('device.open') }}</el-radio>
          <el-radio :label="2" v-model="userInfo.sms_status">{{ $t('device.close') }}</el-radio>
        </el-form-item>
        <el-form-item :label="$t('center.call')" label-width="120px" prop="call_status">
          <el-radio :label="1" v-model="userInfo.call_status">{{ $t('device.open') }}</el-radio>
          <el-radio :label="2" v-model="userInfo.call_status">{{ $t('device.close') }}</el-radio>
        </el-form-item>
        <el-divider/>
        <el-button style="margin-left: 12%" @click="handleSubmit" type="primary">{{ $t('center.update') }}</el-button>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import user from '../model/user'

export default {
  name: 'center',
  data() {
    return {
      userInfo: {
        uid: '',
        account: '',
        mobile: '',
        password: '',
        sms_status: 2,
        balance: 0,
        call_status: 2,
        system_valid: ''
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const ret = await user.getUserInfo({})
      if (ret.code === 200) {
        this.userInfo = ret.data
      } else {
        this.$message.error(ret.message)
      }
    },
    async handleSubmit() {
      const ret = await user.editUserInfo(this.userInfo)
      if (ret.code === 200) {
        this.$message.success(ret.message)
      } else {
        this.$message.error(ret.message)
      }
    },
    handlePay(source) {
      this.$router.push({
        path: '/user/pay',
        query: {
          source
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
